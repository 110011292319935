import { CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { CognitoUserAttributes } from "../shared/cognitoInterfaces";

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID || "",
  ClientId: process.env.REACT_APP_APPCLIENT_ID || "",
});

export function getUser(): CognitoUser | null {


  try {
    return userPool.getCurrentUser();
  } catch (error) {
    console.error("Error getting current user:", error);
    return null;
  }
}

export const getUserData = async (): Promise<CognitoUserAttributes | Error> => {
  try {
    const cognitoUser = getUser();

    if (!cognitoUser) {
      throw new Error('No user is currently signed in');
    }

    // Get user session first
    const session:CognitoUserSession = await new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error('Error getting session:', err);
          reject('Error getting session');
        } else {
          resolve(session);
        }
      });
    });

    // Check if the session is valid
    if (!session.isValid()) {
      throw new Error('Session is not valid');
    }

    // Get user attributes once session is valid
    const userAttributes = await new Promise((resolve, reject) => {
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          console.error('Error getting user attributes:', err);
          reject('Error getting user attributes');
        } else {
          resolve(attributes);
        }
      });
    });
    //console.log({userAttributes})

    // Transform attributes to a more usable format
    const userData: CognitoUserAttributes = {} as any;
    (userAttributes as any).forEach((attribute) => {
      userData[attribute.Name] = attribute.Value;
    });

    // Return user data
    return userData;

  } catch (error) {
    console.error('Error in getUserData:', error);
    return Promise.reject(error);
  }
};

    // TODO: https://stackoverflow.com/questions/53345916/api-gateway-authorizer-accepts-id-token-but-not-access-token
    //you must specify scope at method level in order to use accessToken. OTHERWISE DEFAULT SHOULD USE IDENTITY TOKEN
export const getIdToken = async (): Promise<string | null> => {
  const cognitoUser = getUser(); // Get the current user from Cognito

  if (!cognitoUser) {
    console.log('No user is currently signed in.');
    return null; // Return null if no user is signed in
  }

  return new Promise((resolve) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) {
        console.error('Error getting session:', err);
        resolve(null); // Return null if there is an error getting the session
        return;
      }

      // Return the access token
      const IdToken = session.getIdToken().getJwtToken();
      //const accessToken = session.getAccessToken().getJwtToken();
      //console.log(`AUTH.ts:`,`IDTOK(used for auth with sam local):`,IdToken,`access token (used for auth when deployed?)`,accessToken)
      const expiration = session.getIdToken().getExpiration();

      if (expiration <= Math.floor(Date.now() / 1000)) {
        console.warn('Access token has expired.');
        resolve(null); // Return null if the token is expired
      } else {
        resolve(IdToken); // Return the access token if it is valid
      }
    });
  });
};

