import * as React from "react";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { doLogin } from "../redux/slices/authSlice";
import "./styles/login.scss";
import { useState } from "react";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID || "",
  ClientId: process.env.REACT_APP_APPCLIENT_ID || "",
});

const Login = () => {
  const dispatch = useDispatch();
  const [loginResult, setLoginResult] = useState<string | null>(null);
  const [isPasswordReset, setIsPasswordReset] = useState(false); // Toggle between login and reset password
  const [isCodeSent, setIsCodeSent] = useState(false); // Whether a password reset code has been sent
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false); // Handle new password required
  const lastVisitedPage = useSelector((state: RootState) => state.navigation.lastVisitedPage);
  const navigate=useNavigate()

  // Handle login
  const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    const cognitoUser = new CognitoUser({
      Username: values.email,
      Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: values.email,
      Password: values.password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async(session: CognitoUserSession) => {
        setLoginResult("Login successful!");

        const idToken = session.getIdToken()
        const accessToken = session.getAccessToken()
        const refreshToken = session.getRefreshToken()
    
        // Dispatch the doLogin action with all necessary tokens
        await dispatch(doLogin({ idToken, accessToken, refreshToken }));
        setSubmitting(false);

        console.log(`NAVIG:`,lastVisitedPage)
        navigate(lastVisitedPage || '/'); // Navigate to last visited page or default to '/'
      },
      onFailure: (err) => {
        if (err.code === 'NewPasswordRequiredException') {
          setIsNewPasswordRequired(true);
        } else {
          setLoginResult(err.message);
        }
        setSubmitting(false);
      },
      newPasswordRequired: (userAttributes: any) => {
        // Handle new password required challenge
        setIsNewPasswordRequired(true);
      },
    });
  };

  // Handle password reset request (forgot password)
  const handleForgotPassword = (email: string, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: () => {
        setIsCodeSent(true);
        setLoginResult("Password reset code sent.");
        setSubmitting(false);
      },
      onFailure: (err) => {
        setLoginResult(err.message);
        setSubmitting(false);
      },
    });
  };

  // Handle confirm new password with code
  const handleConfirmNewPassword = (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    const cognitoUser = new CognitoUser({
      Username: values.email,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(values.code, values.newPassword, {
      onSuccess: () => {
        setLoginResult("Password reset successful! You can now log in.");
        setIsPasswordReset(false);
        setIsCodeSent(false);
        setIsNewPasswordRequired(false);
        setSubmitting(false);
      },
      onFailure: (err) => {
        setLoginResult(err.message);
        setSubmitting(false);
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      code: "", // For password reset
      newPassword: "", // For password reset
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: (Yup.string() as any).when("isPasswordReset", {
        is: false, // Password is required for login, not for password reset
        then: Yup.string().required("Password is required."),
      }),
      newPassword: (Yup.string() as any).when("isNewPasswordRequired", {
        is: true, // New password is required if resetting password
        then: Yup.string().required("New password required."),
      }),
      code: (Yup.string() as any).when("isCodeSent", {
        is: true, // Reset code required if code is sent
        then: Yup.string().required("Code required."),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (isNewPasswordRequired) {
        handleConfirmNewPassword(values, { setSubmitting });
      } else if (isPasswordReset && isCodeSent) {
        handleConfirmNewPassword(values, { setSubmitting });
      } else if (isPasswordReset) {
        handleForgotPassword(values.email, { setSubmitting });
      } else {
        handleSubmit(values, { setSubmitting });
      }
    },
  });

  return (
    <>
      <div className="login__container">
        <form className="login__form" onSubmit={formik.handleSubmit}>
          <h1>{isNewPasswordRequired ? "Reset Password" : isPasswordReset ? "Reset Password" : "Sign In"}</h1>

          <label className="login__fieldName" htmlFor="email">
            Email Address
          </label>
          <input
            className="login__inputField"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="login__error">{formik.errors.email}</div>
          ) : null}

          {!isPasswordReset && !isNewPasswordRequired && (
            <>
              <label className="login__fieldName" htmlFor="password">
                Password
              </label>
              <input
                className="login__inputField"
                name="password"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="login__error">{formik.errors.password}</div>
              ) : null}
            </>
          )}

          {(isPasswordReset || isNewPasswordRequired) && isCodeSent && (
            <>
              <label className="login__fieldName" htmlFor="code">
                Verification Code
              </label>
              <input
                className="login__inputField"
                name="code"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.code}
              />
              {formik.touched.code && formik.errors.code ? (
                <div className="login__error">{formik.errors.code}</div>
              ) : null}

              <label className="login__fieldName" htmlFor="newPassword">
                New Password
              </label>
              <input
                className="login__inputField"
                name="newPassword"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="login__error">{formik.errors.newPassword}</div>
              ) : null}
            </>
          )}

          <button
            className="login__submitButton"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {isNewPasswordRequired
              ? "Confirm Password Reset"
              : isPasswordReset && isCodeSent
              ? "Confirm Password Reset"
              : isPasswordReset
              ? "Send Reset Code"
              : formik.isSubmitting
              ? "Logging in..."
              : "Log in"}
          </button>

          {!isPasswordReset && !isNewPasswordRequired && (
            <button
              type="button"
              className="login__submitButton"
              onClick={() => setIsPasswordReset(true)}
            >
              Forgot Password?
            </button>
          )}
        </form>

        {/* Display login result */}
        {loginResult && <div className="login__result">{loginResult}</div>}
      </div>
    </>
  );
};

export default Login;