export enum myCognitoGroups {
  NurseGroup = 'NurseGroup',
  MyCognitoGroup = 'MyCognitoGroup',
}
//parsed from getUser() not TOKEN anymore

//this is an ID TOKEN NOT AN AUTH TOKEN
export interface CognitoUserAttributes {
    sub: string; // Subject - unique identifier for the user
    ["cognito:groups"]: string[]; // eg ['MyCognitoGroup']
    email?: string; 
    email_verified?: boolean; 
    aud: string; // Audience - the app client ID
    event_id?: string; // Unique event ID
    token_use: "id"; // Indicates this is an ID token
    auth_time: number; // Time when the authentication occurred (Unix timestamp)
    iss: string; // Issuer - the Cognito user pool
    exp: number; // Expiration time (Unix timestamp)
    iat: number; // Issued at time (Unix timestamp)
    ["cognito:username"]: string; 
    name?: string; 
    phone_number?: string; 
    phone_number_verified?: boolean; 
    [key: string]: any; // Allows additional custom claims if present
    "custom:slackId":string; //"Value": "edit-address-price" used by property column display
  };

  /*
  decodedToken: {
    sub: '54ccaf55-201a-4331-9fea-473cdb4761a8',
    'cognito:groups': [ 'MyCognitoGroup' ],
    email_verified: true,
    iss: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_06MQbxWjT',
    phone_number_verified: false,
    'cognito:username': 'yzpaul@gmail.com',
    origin_jti: 'a50d0a7e-361f-4784-827b-7704782a0812',
    aud: '7pr6jm68hd2lh8iojrgmnct05j',
    event_id: '9d92bb7b-37b2-49c5-81c0-d6996004f44f',
    token_use: 'id',
    auth_time: 1732664842,
    name: 'Paul',
    phone_number: '+16783465445',
    exp: 1733783950,
    iat: 1733780350,
    jti: '0d01cef2-6624-48df-bb40-a7643c376837',
    email: 'yzpaul@gmail.com'
  }
   */