/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import * as React from 'react';
import {Navbar, Nav, NavDropdown, Button} from 'react-bootstrap';
//import { version } from "./package.json"; // this is symlink: mklink /D "C:\Users\king\Desktop\_REPOS\_PERSONAL\paulweiler-serverlessV2\frontend\src\old_site\components\package.json" "C:\Users\king\Desktop\_REPOS\_PERSONAL\paulweiler-serverlessV2\frontend\package.json"
import Dialog from '@mui/material/Dialog';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import thesis from '../files/Weiler_A_Paul_MS.pdf';
import mcpaper from '../files/MinecraftManuscript.pdf';
import Login from '../login/Login'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { AuthState, doLogout } from '../redux/slices/authSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../pages/styles/header.scss'
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/store';
import { myCognitoGroups } from '../shared/cognitoInterfaces';
import { setLastVisitedPage } from '../redux/slices/navigationSlice';

let version='2.3.1'

export function NavBarPaul() {
  return (
  <Navbar bg="myColor" expand="lg" sticky="top">
    <Navbar.Brand>v{version}</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse role="navigation" id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="https://docs.google.com/document/d/1fUUru13PjWXACBxOVoq5NUO7K159tKbgmFkF4drrJWg/export?format=pdf" rel="noreferrer">Resum&eacute; (PDF <GetAppOutlinedIcon />)</Nav.Link>
        <NavDropdown title="Projects" id="basic-nav-dropdown">
          <NavDropdown.Item href="quiz">Programming Quiz</NavDropdown.Item>
          {/* <NavDropdown.Item href="property">Property Analysis</NavDropdown.Item> */}
          {/*When adding new page also go here for routing: pages/Routing.tsx */}
          <NavDropdown.Item href="articles">Dev Articles I'm Reading</NavDropdown.Item>
          <NavDropdown.Item href="paulweb">This website</NavDropdown.Item>
          <NavDropdown.Item href="screeps">Screeps (OS model design)</NavDropdown.Item>
          <NavDropdown.Item href="opensource">Open Source Contributions</NavDropdown.Item>
          <NavDropdown.Item href="resumeai">Machine Learning Resume Analysis Tool</NavDropdown.Item>          
          <NavDropdown.Item href="exampleapi">Example Paginated API</NavDropdown.Item>          
          <NavDropdown.Item href="property">Property Search</NavDropdown.Item>          
          <NavDropdown.Item href="mortgage">Yield/Mortgage Predictions</NavDropdown.Item>          
          <NavDropdown.Item href="nurses">Nurse Dice Roll</NavDropdown.Item>          
        </NavDropdown>
        <NavDropdown title="Publications" id="basic-nav-dropdown">
        <NavDropdown.Header>Medium Articles</NavDropdown.Header>
          <NavDropdown.Item href="https://yzpaul.medium.com/var-const-let-null-and-undefined-a-quick-intro-to-variables-in-javascript-657eb1ff8307" target="_blank" rel="noreferrer">Var, Const, and let in JS</NavDropdown.Item>
          <NavDropdown.Item href="https://yzpaul.medium.com/angular-post-request-to-c-api-93b11ac515d7" target="_blank" rel="noreferrer">Writing an Angular POST request to C#</NavDropdown.Item>
          <NavDropdown.Item href="https://yzpaul.medium.com/what-is-javascript-an-intro-for-programmers-a7ca468898a2" target="_blank" rel="noreferrer">A quick JavaScript intro for programmers</NavDropdown.Item>
          <NavDropdown.Header>Academic Journal Articles</NavDropdown.Header>
          <NavDropdown.Item href={thesis} target="_blank" rel="noreferrer">Thesis: Great Fantasy Migration (PDF <GetAppOutlinedIcon />)</NavDropdown.Item>
          <NavDropdown.Item href={mcpaper} target="_blank" rel="noreferrer">Personality and Minecraft: An Exploratory Study of the Big Five and Narcissism (PDF <GetAppOutlinedIcon />)</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="nurses">Nurse Dice Roll</Nav.Link>
        <SimpleModal />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
  )
}

export default function SimpleModal() {
  const [open, setOpen] = React.useState(false);
  const userData:AuthState = useSelector((state:any) => {return state.auth});
  const dispatch = useDispatch();
    const lastVisitedPage = useSelector((state: RootState) => state.navigation.lastVisitedPage);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    //console.log(`nodal close`)
    setOpen(false);
    console.log({lastVisitedPage})
    navigate(lastVisitedPage || '/');
  };

  const handleLogout=()=>{
    dispatch(doLogout())
    navigate('/');
  }

    // Close modal when userData changes
    useEffect(() => {
      if(userData?.userData?.['cognito:groups']?.includes(myCognitoGroups.NurseGroup)){
        console.log(`user is nurse group. auto-nav there`)
        dispatch(setLastVisitedPage(`/nurses`));
      }
      //console.log(`HEADER state change`,{userData})
      if (open && userData.isLoggedIn) {
        handleClose();
      }
    }, [userData]);

  return (
    userData.isLoggedIn?(<div>
            <Button onClick={handleLogout} className="signinButton">
        {`Sign Out ${userData.userData.email}`}
      </Button>
    </div>):

    //ELSE IF NOT SIGNED IN:
    <div>
      {/* menu bar button */}
      <Button onClick={handleOpen} className="signinButton">
        Sign In
      </Button>

      <Dialog
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
          <Login />
      </Dialog>
    </div>
  );
}
