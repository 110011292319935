/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import "./styles/Main-Wrapper.css";
import "../index.css";
import "react-notifications/lib/notifications.css";
//import 'bootstrap/dist/css/bootstrap.min.css'; //loaded via CDN in index.html instead 

import * as React from "react" 
import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../login/Login";
import { SignUp } from "../login/SignUp";
import { Home } from "./home";
import { NavBarPaul } from "./header";

// ONLY CALL NotificationContainer AT TOP LEVEL (once)!
import {
  NotificationContainer,
} from "react-notifications";
import { CaptureLastVisitedPage } from "../components/captureLastVisitedPage";

const HtmlPage = React.lazy(() => import("./htmlpages"));
const QuestionPage = React.lazy(() => import("./questionpage"));
const ArticlePage = React.lazy(() => import("./articlepage"));
const PaulWebPage = React.lazy(() => import("./paulweb"));
const ScreepsPage = React.lazy(() => import("./screeps"));
const OpenSourcePage = React.lazy(() => import("./opensource_contrib"));
const AiResumePage = React.lazy(() => import("./airesume"));
const ExampleApiPage = React.lazy(() => import("./example_api"));
const PropertyPage = React.lazy(() => import("./propertypage"));
const MortgagePage = React.lazy(() => import("./mortgagepage"));
const NursePage = React.lazy(() => import("./nurses"));

export const RoutingOldSite = () => {
  //const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

        <div>
            {/*<React.StrictMode>*/}
              <BrowserRouter>
              <NotificationContainer />
              <NavBarPaul/>
                <CaptureLastVisitedPage />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/quiz" element={<Suspense fallback={<div>Loading...</div>}><QuestionPage/></Suspense>} />
                  <Route path="/screeps" element={<Suspense fallback={<div>Loading...</div>}><ScreepsPage /></Suspense>} />
                  <Route path="/articles" element={<Suspense fallback={<div>Loading...</div>}><ArticlePage/></Suspense>} />
                  <Route path="/paulweb" element={<Suspense fallback={<div>Loading...</div>}><PaulWebPage/></Suspense>} />
                  <Route path="/opensource" element={<Suspense fallback={<div>Loading...</div>}><OpenSourcePage/></Suspense>} />
                  <Route path="/resumeai" element={<Suspense fallback={<div>Loading...</div>}><AiResumePage/></Suspense>} />
                  <Route path="/exampleapi" element={<Suspense fallback={<div>Loading...</div>}><ExampleApiPage/></Suspense>} />
                  <Route path="/property" element={<Suspense fallback={<div>Loading...</div>}><PropertyPage/></Suspense>} />
                  <Route path="/mortgage" element={<Suspense fallback={<div>Loading...</div>}><MortgagePage/></Suspense>} />
                  <Route path="/nurses" element={<Suspense fallback={<div>Loading...</div>}><NursePage/></Suspense>} />
                  {/* used by clicking UI icons */}
                  <Route path="/htmlpages/:filename" element={<Suspense fallback={<div>Loading...</div>}><HtmlPage/></Suspense>} />

                </Routes>
              </BrowserRouter>
            {/*</React.StrictMode>*/}
            </div>
    </div>
  );
}
